body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-background:before {
    content: ' ';
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.1;
    background-image: url('/src/assets/illustration.jpg');
    background-repeat: no-repeat;
    /* background-position: 50% 0; */
    background-size: cover;
}

.app-background-content {
    position: relative;
}

.logo-preview {
    border: 1px dashed lightgray;
    width: 152px;
    height: auto;
    border-radius: 2%;
}

.logo-watermark {
    width: 100px;
    height: auto;
    border-radius: 2%;
    z-index: -1;
    position: fixed;
    top: 70px;
    right: 10px;
    /* opacity: 0.8; */
}